<template>
  <v-container
    class="has-background-image fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
      dense
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
        lg="4"
      >
        <v-card class="glassy-card">
          <a
            href="https://infiniteinfo.co"
            name="Infinite Info"
            title="Infinite Info"
            target="_blank"
          >
            <v-img
              width="200"
              class="mx-auto"
              src="https://via.imageplaceholder.com/200"
            />
          </a>
          <v-card-text>
            <v-form
              :disabled="loading"
              @submit.prevent="login"
            >
              <v-text-field
                v-model="form.email"
                :label="$t('auth.email')"
                prepend-inner-icon="mdi-email"
                type="email"
                outlined
                :error-messages="errors.email"
                required
              />
              <v-text-field
                v-model="form.password"
                :label="$t('auth.password')"
                prepend-inner-icon="mdi-lock"
                type="password"
                outlined
                :error-messages="errors.password"
                required
              />
              <v-btn
                color="primary"
                type="submit"
                :loading="loading"
                x-large
                block
                dark
              >
                {{ $t('auth.login') }}
              </v-btn>
              <v-btn
                color="primary"
                :to="{ name: 'register', query: $route.query }"
                block
                dark
                text
              >
                {{ $t('auth.have_an_account?') }}
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'LoginPage',
  data () {
    return {
      form: {
        email: process.env.NODE_ENV === 'production' ? '' : 'fathi@email.com',
        password: process.env.NODE_ENV === 'production' ? '' : 'password',
      },
      loading: false,
      errors: {},
    }
  },

  methods: {
    login () {
      const self = this
      const payload = self.form
      self.loading = true

      self.$store.dispatch('login', payload).then(() => {
        self.loading = false
        if (self.$route.query.redirect_to) {
          self.$router.push(self.$route.query.redirect_to)
        } else {
          self.$router.push({ name: 'home' })
        }
      }).catch(err => {
        self.loading = false
        self.errors = err.response.data.errors
      })
    },
  },
}
</script>
